<template>
  <div>
    <Header @toggle-subscribe="subscribeTvShow" v-if="$route.meta.authOnly" />
    <div class="container-fluid">
      <div class="row heightFull">
        <div
          class="col-lg-2 ps-0 pe-0 CustomSidebar"
          :class="{
            Newsidebar: showSidebar,
            'collapsed-sidebar': !showSidebar,
          }"
          id="cc-side"
        >
          <sidebar v-if="$route.meta.authOnly" />
        </div>
        <div
          class="col-md-12 col-lg-10 px-0"
          :class="{
            'col-lg-10': showSidebar,
            'col-lg-12': !showSidebar,
            'px-0': showSidebar,
          }"
        >
          <router-view />
        </div>
      </div>

      <!-- <auto-logout @auto-logout="logout"></auto-logout> -->
 
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar.vue"; 
import Web3 from "web3";
import exactMath from "exact-math";
import ApiClass from "./api/api";

//////////////////////// LIVE //////////////////////////////////////
const web = new Web3("https://bsc-dataseed.binance.org/");
const busdAddress = "0x55d398326f99059fF775485246999027B3197955";

const minABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    type: "function",
  },
];
const contract = new web.eth.Contract(minABI, busdAddress);
const MathConfig = { returnString: true, eMinus: Infinity, ePlus: Infinity };

export default {
  name: "App",
  data() {
    return {
      showSidebar: false,
      x: null,
      wallet_addr: "",
      MIN_DEPOSIT_AMT: 100 
    };
  },
  components: {
    Header,
    Sidebar, 
  }, 
  async mounted() {
    this.startInterval_();
  },
  watch: {
    "$store.state.WALLET_ADDRESS": async function () {
      this.wallet_addr = this.$store.state.WALLET_ADDRESS;
      this.startInterval_();
    },
  },
  methods: {
    subscribeTvShow(showId) {
      this.showSidebar = showId;
    },
    startInterval_() {
      this.wallet_addr = this.$store.state.WALLET_ADDRESS;

      if (this.wallet_addr == "") {
        console.log("interval clear");
        clearInterval(this.x);
      }
      if (localStorage.getItem("token")) {
        this.x = setInterval(this.findwallet, 60000, this.wallet_addr);
      }
    },
    async getAccountBalance(holderAddress) {
      const balance = await contract.methods.balanceOf(holderAddress).call();
      let b = exactMath.div(balance, 10 ** 18, MathConfig);
      return parseFloat(b);
    },
    async findwallet(wallet) {
      let bal = await this.getAccountBalance(wallet);

      if (bal > (this.MIN_DEPOSIT_AMT - 1)) {
        console.log(
          " hit check wallet => " + wallet + "  total balance => " + bal
        );
        // hit api in backend
        let response = await ApiClass.postRequest("transaction/deposit", true);

        if (response?.data?.status_code == 1) {
          console.log(response?.data?.message);
          return;
        }
      }
    }, 
  },
};
</script>

<style>
body {
    background: linear-gradient(90deg,#011726,24.0826%,#046 48.1651%,74.0826%,#001827);
}

#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

:root {
  --bg-view: #f6f8fe;
  --main-bg: #f7f7f7;
  --white-bg: #fff;
  --black-bg: #fff;
  --border: #d6d9df;
  --bg-1: #f2f6ff;
  --yellow-bg: #e45d1b;
  --white: #fff;
  --black: #000;
  --black-2: #2a2627;
  --grey: #888888;
  --grey2: #808080;
  --border: #dddddd;
  --green: #21bf73;
  --red: #d90429;
  --btn: #262391;
  --dark-blue: #0e4051;
  --lot-green: #35a27e;
  --lot-red: #be5150;
  --lot-bg-green: #e7f8f2;
  --lot-bg-red: #f9ebea;
  --lot-datetime: #b8bdc9;
  --text-muted: #8c9097;
  --raxios-muted: linear-gradient(
    90deg,
    rgba(85, 115, 179, 1) 95%,
    rgba(85, 115, 179, 1) 100%
  );

  --raxios-color: rgb(85, 115, 179);

  --raxios-btn-color: #f3562b;
  --blueN: #88f0e5;
  --Orange: #f9b85e;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Bold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Black.ttf);
  font-weight: 900;
  font-display: swap;
}

/**====================================
          AUTH SEC CSS START
=======================================**/

section.auth-sec {
  /* min-height: 100vh; */
  display: flex;
}

section.auth-sec .container {
  padding: 25px 20px 0px 20px;
}

.auth-image {
  text-align: center;
}

.auth-image img {
  max-width: 70%;
}

.login-heading h1 {
  color: var(--black-2);
  font-size: 24px;
  font-weight: 600;
}

.login-heading p {
  font-size: 14px;
  font-weight: 400;
  color: var(--grey);
}

.label-box label {
  font-size: 14px;
  font-weight: 600;
  color: var(--black-2);
  /* margin-bottom: 13px; */
}

:is(body .form-control, .form-select,) {
  font-size: 14px;
  font-weight: 400;
  color: #FFF;
  height: 50px;
  background: #5bffee1f;
    border: 1px solid #5bffee38;
}

body .form-control::placeholder {
  color: var(--grey2);
  font-size: 14px;
  font-weight: 400;
}

body .form-control:focus {
  background: #5bffee1f;
    border: 1px solid #5bffee38;
    color: #FFF;
  box-shadow: none;
}

.submit-button .btn-primary {
  width: 100%;
}

body .btn-primary {
  background: linear-gradient(90deg,#4bb9cf,6.97674%,#44b4ce 13.9535%,56.9767%,#2499c3);
  padding: 5px 15px;
  text-align: center;
  color: #fff;
  border-radius: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: none !important;
}

body .btn-primary:hover {
  background:linear-gradient(90deg, #219bb3, 6.97674%, #44b4ce 13.9535%, 56.9767%, #006285) ;
  color: #FFF;
  transition: all 0.5s ease-in-out;
}

.spinner-border {
  --bs-spinner-width: 1rem !important;
  --bs-spinner-height: 1rem !important;
}

.auth-text p {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: var(--white);
  letter-spacing: 0.8px;
}

.error_msg {
  color: #ffb754;
  font-size: 12px;
  font-weight: 500;
}

/**================ OTP INPUT FIELD CSS START ================ **/

.otp-input div {
  justify-content: space-between;
  width: 100%;
}

input.otp-input {
  min-height: 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  width: 40px;
  box-shadow: -1px 0px 3px rgb(0 0 0 / 25%);
  padding: 10px;
  margin-left: 3px;
  color: #FFF;
  height: 50px;
  background: #5bffee1f;
 border: 1px solid #5bffee38 !important;
}

input.otp-input:focus-visible {
  outline: none;
}

.mb-33 {
  margin-bottom: 33px;
}

/* auth pages */
.heightFull {
  height: 100vh;
}

.form-Wrapper {
  box-shadow: -1px 0px 4px rgb(0 0 0 / 25%);
  margin: auto;
}

.auth-text a,
.textNew a {
  color: var(--blueN);
}
.auth-text a:hover {
  text-decoration: none !important;
}

body .form-check-input:checked {
  background-color: var(--blueN);
  border-color: var(--blueN);
}

@media screen and (max-width: 767px) {
  .ContainerNew {
    padding: 0 20px;
  }

  .ContainerNew::before {
    width: 100%;
  }
}

/* Sidebar code */
.CustomSidebar.collapsed-sidebar {
  display: none;
}

.CustomSidebar .router-link-active {
  background-color: #0b3b49;
  color: #fff !important;
}
.CustomSidebar .router-link-active img,
.btn-close {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(182deg) brightness(105%) contrast(101%);
}

.cardTrade {
  padding: 24px 35px;
  background-color: #5bffee1f;
  color: #FFF;
  transition: all 0.5s ease-in-out;
  border: 0rem solid transparent;
  border-radius: 1.25rem;
  /* box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05); */
  box-shadow: 0px 10px 20px rgba(55, 55, 89, 0.08);
}

.coinType span {
  width: 40px;
  height: 40px;
}

.greenBg span {
  background-color: #32a9544d;
}
.redBg span {
  background-color: rgb(216 24 24 / 15%);
}

:is(.currency-text h5, .currency-text span) {
  font-size: 14px;
  color: #FFF;
  font-weight: 500;
}

.textGrey {
  color: #adadad !important;
}

.cursorPointer {
  cursor: pointer;
}

/* ===========PAGINATION CSS START============== */

.pagination {
  justify-content: end !important;
  background-color: transparent !important;
}

.page-link {
  z-index: 3 !important;
  color: var(--lot-green) !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 2px;
  border-color: var(--border) !important;
  font-size: var(--fs-15) !important;
}

.page-item.active .page-link {
  z-index: 3 !important;
  color: var(--white) !important;
  box-shadow: none !important;
  background-color: var(--blueN) !important;
  border-color: var(--blueN) !important;
}

.pagination_box ul li {
  border: none !important;
  margin-bottom: 20px !important;
  margin-bottom: 0 !important;
  color: var(--lot-green);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  box-shadow: none !important;
}

.VuePagination__pagination {
  /* box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px !important; */
  background-color: var(--yellow-bg);
  color: var(--yellow-bg);
  gap: 4px;
}

.VuePagination nav p {
  color:#fff;
  font-size: var(--fs-14);
}

/* ===========PAGINATION CSS FINISH============== */
.img_log {
  border: 2px solid #323232;
  max-width: 120px;
  padding: 32px 0;
  border-radius: 50%;
}

.input-group input + span {
  position: absolute;
  right: 3px;
  top: 7px;
  z-index: 10;
  background-color: #0000;
  border: 0;
}


@media screen and (max-width:767px){
  .refer-link{
    width: 100% !important;
  }

  .cardTrade {
      padding: 20px;
  }

  .level__income th {
    white-space: pre;
}

.left-side{
    width: 100%;
  }

  .logo img {
      width: 170px;
  } 

  body .pagination_box nav {
    flex-direction: column-reverse;
    gap: 12px;
  }
  
}

.security-form {
  max-width:690px;
  margin:auto;
}

.pagination_box .VuePagination {
    width: 100%;
}

.pagination_box nav {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.pagination_box nav ul,
.VuePagination nav p{
    margin: 0;
}

body .form-Wrapper{
    max-width: 500px !important;
    margin: auto ;
}

input.otp-input {
    max-width: 60px;
    width: 99%;
    height: 60px;
    margin: 0;
    border-color: var(--white);
}
input.otp-input:focus {
    border-color: var(--blueN) !important;
}


.tree-container {
    width: 100% !important;
    /* border: 1px solid var(--active) !important; */
    overflow: scroll !important;
    direction:ltr ;
    
}

.bg-jexa{
  background-color: #5bffee1f;
}

body .table>:not(caption)>*>* {
    background-color: #5bffee1f;
    border-bottom: 1px solid #5bffee38;
    color: #FFF;
}

::-webkit-scrollbar{
  background: #5bffee1f;
  width: 5px;
  height:5px;
}

::-webkit-scrollbar-thumb{
  background-color: #5bffee38;
}

body .form-control::placeholder{
    color: #f6f6f6;
}

.modal .modal-content{
  background-color: #003a58;
}

</style>
